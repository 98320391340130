import { SameValue } from '../262';
import { PartitionNumberPattern } from './PartitionNumberPattern';
import { CollapseNumberRange } from './CollapseNumberRange';
import { FormatApproximately } from './FormatApproximately';
/**
 * https://tc39.es/ecma402/#sec-partitionnumberrangepattern
 */
export function PartitionNumberRangePattern(numberFormat, x, y, _a) {
    var getInternalSlots = _a.getInternalSlots;
    var internalSlots = getInternalSlots(numberFormat);
    if (isNaN(x) || isNaN(y)) {
        throw new RangeError('Input must be a number');
    }
    if (isFinite(x)) {
        if (isFinite(y) && y < x) {
            throw new RangeError('Y input must be bigger than X');
        }
        else if (y == Number.NEGATIVE_INFINITY) {
            throw new RangeError('Y input must not be NegativeInfinity');
        }
        else if (SameValue(y, -0) && x >= 0) {
            throw new RangeError('Y input must be bigger than X');
        }
    }
    else if (x == Number.POSITIVE_INFINITY) {
        if (isFinite(y) || y == Number.NEGATIVE_INFINITY || SameValue(y, -0)) {
            throw new RangeError('Y input must be bigger than X');
        }
    }
    else if (SameValue(x, -0)) {
        if (isFinite(y) && y < 0) {
            throw new RangeError('Y input must be bigger than X');
        }
        else if (y == Number.NEGATIVE_INFINITY) {
            throw new RangeError('Y input must be bigger than X');
        }
    }
    var result = [];
    var xResult = PartitionNumberPattern(numberFormat, x, { getInternalSlots: getInternalSlots });
    var yResult = PartitionNumberPattern(numberFormat, y, { getInternalSlots: getInternalSlots });
    if (xResult === yResult) {
        return FormatApproximately(numberFormat, xResult, { getInternalSlots: getInternalSlots });
    }
    for (var _i = 0, xResult_1 = xResult; _i < xResult_1.length; _i++) {
        var r = xResult_1[_i];
        r.source = 'startRange';
    }
    result = result.concat(xResult);
    var symbols = internalSlots.dataLocaleData.numbers.symbols[internalSlots.numberingSystem];
    var rangeSeparator = symbols.timeSeparator;
    result.push({ type: 'literal', value: rangeSeparator, source: 'shared' });
    for (var _b = 0, yResult_1 = yResult; _b < yResult_1.length; _b++) {
        var r = yResult_1[_b];
        r.source = 'endRange';
    }
    result = result.concat(yResult);
    return CollapseNumberRange(result);
}
